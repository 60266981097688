import React from "react";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";

const DisputeResolutions = () => {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div className=" footer-content mt-5">
        <main className="main">
          <div className="container">
            <h2 class="sectionTitle"> Dispute Resolution </h2>
            <div className="terms-content">
              <p>
                We have the right to refuse to establish an Account for any
                reason.
              </p>
              <p>
                We may suspend or close your account for any violation,
                suspected violation of this Agreement, or other valid reason. If
                your account is closed, the full account balance will be paid
                unless there is a specific reason not to do so.
              </p>
              <p>
                We recognize that errors and typos may occur, but if the
                information provided during registration is found to be
                incorrect, we may close your account and We reserve the right to
                confiscate funds. Similarly, failure to verify identity will
                result in the account being closed and the winnings being
                forfeited.
              </p>
              <p>
                If we find that you are using the Website in a non-profitable
                manner, we will adjust your account to remove all funds
                generated from that activity and your account may be permanently
                closed.
              </p>
              <p>We may refuse, terminate or limit any bet for any reason.</p>
              <p>
                We will void all or part of your wager (and/or close your
                account) if we believe that any of the following apply:
              </p>
              <ul>
                <li>
                  ➔ You or people related to you may have directly or indirectly
                  influenced the outcome of events.
                </li>
                <li>
                  ➔ You or anyone connected to you may have access to inside
                  information that may affect the event's outcome.
                </li>
                <li>
                  ➔ You or anyone related to you engages in arbitrage trading or
                  match betting.
                </li>
                <li>
                  ➔ You and the people associated with you are disregarding the
                  rules, either directly or indirectly.
                </li>
                <li>
                  ➔ The outcome of the case was directly or indirectly
                  influenced by criminal activity. For example, an announcement
                  related to an event causes a significant change in the odds.
                </li>
                <li>
                  ➔ A bet was offered, placed, and/or accepted that was not
                  accepted by us due to technical problems, errors, or typos on
                  the website at the time and/or for any reason beyond our
                  control.{" "}
                </li>
              </ul>
              <p>
                If you engage in any illegal or fraudulent activity, have a
                dispute with a creditor, or otherwise be detrimental to the
                Website in connection with your use of the Website (or the use
                of the Website by any third party); we will freeze your use of
                the website, close your account and/or cancel your wager.
              </p>
              <p>
                If we suspect that the intended spirit of a game or promotion
                has been abused, we reserve the right to suspend or block access
                to the game in question.{" "}
              </p>
              <p>
                All transactions are monitored and/or verified to prevent money
                laundering.{" "}
              </p>
            </div>
          </div>
        </main>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default DisputeResolutions;
